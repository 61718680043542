import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons'
import { faPhoneAlt, faGlobe, faEnvelope, faHouse } from '@fortawesome/pro-solid-svg-icons'
import { MDBRow, MDBCol, MDBBtn } from 'mdbreact'
import { GatsbyImage } from 'gatsby-plugin-image'
import Link from 'gatsby-link'

class cardCompaniesContacts extends React.Component {
  render() {
    return (
      <>
        <MDBRow>
          {this.props.data.map((company, index) => (
            <MDBCol key={index} lg="4" md="6" className="pb-30 d-flex">
              <div className="card promoting-card flex-fill">
                {/* {console.log(this.props.data)} */}
                  <div className={company.profile ? "partnercontainer" : "companycontainer"}>
                    {company.profile && (
                      <div className="partneroverlay rounded-top">
                        <div className="partnertext">{company.profile}</div>
                      </div>
                  )}
                  <div className="card-body d-flex flex-row mt-3" style={{ minHeight: '100px', maxHeight: '100px', alignItems: 'center', }} >
                    <div style={{ minWidth: '48px', maxWidth: '150px' }}>
                      {company.image && (
                          <GatsbyImage
                            image={company.image.childImageSharp.gatsbyImageData}
                            className="mr-3"
                            alt={`company logo for ${company.subtitle}`}
                          />
                      )}
                    </div>
                    <div>
                      <h3 className="font-alt font-w-700 letter-spacing-1 text-xs-large text-large">
                        {company.title}
                      </h3>
                    </div>
                  </div>
                  <div className="card-body-prods mb-3">
                    <div className="pt-3" style={{ minHeight: '210px', maxHeight: '220px' }} >
                     <span className="align-top ml-1">
                          <FontAwesomeIcon icon={faHouse} className="mr-3 va-m" />
                     </span>                     
                    <span className="ml-1 card-address">
                      <p className="text-card-small mb-0 font-w-700">
                        <strong>{company.subtitle}</strong>
                      </p>
                      <p className="text-card-small" style={{ whiteSpace: 'pre-wrap' }} >
                        {company.description}
                      </p>
                    </span>

                    </div>

                    <ul className="fa-ul card-text text-card-small">
                    {company.tel && (
                        <>
                      <li>
                        <span className="fa-li">
                          <FontAwesomeIcon icon={faPhoneAlt} className="mr-3 va-m" />
                        </span>
                        {company.tel}
                      </li>
                      </>
                    )}

                      {company.email && (
                        <>
                          <li>
                            <span className="fa-li"> <FontAwesomeIcon icon={faEnvelope} className="mr-3 va-m" /> </span>
                            <a href={'mailto:' + company.email} className="effect" rel="noopener" target="_blank" > {company.email} </a>
                          </li>
                        </>
                      )}

                      {company.icon && (
                        <>
                          <li>
                            <span className="fa-li"> <FontAwesomeIcon icon={faGlobe} className="mr-3 va-m" /> </span>
                            <a href={company.icon} className="effect" target="_blank"> {company.icon} </a>
                          </li>
                        </>
                      )}
                    </ul>
                  </div>
                </div>

                <div className="card-footer">
                  <div className="text-center">
                    {company.link.charAt(0) == '/' ? (
                      <Link to={company.link} className="btn btn-block btn-md btn-mdb-color" aria-label={`Visit ${company.title}`} >
                        <FontAwesomeIcon icon={faChevronRight} className="mr-2" />
                        {company.linktext}
                      </Link>
                    ) : (
                      <a href={company.link} className="btn btn-block btn-md btn-mdb-color" target="_blank" rel="noopener" aria-label={`Visit ${company.title}`} >
                        <FontAwesomeIcon icon={faChevronRight} className="mr-2" />
                        {company.linktext}
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </MDBCol>
          ))}
        </MDBRow>
      </>
    )
  }
}

export default cardCompaniesContacts
